import React from 'react'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import { updateLead } from 'Actions'
import { Store } from 'Store'

import Header from 'components/Header'
import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import LeadGenForm from 'components/LeadGenForm'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function Landing () {
    const { ref, properties } = useBlock('landing.body', propertiesSchema)
    const { state, dispatch } = React.useContext(Store)

    const initialValues = React.useMemo(() => {
        return state.lead.fields
    }, [state.lead.fields])

    return (
        <>
            <Header/>

            <div ref={ref} className={styles.grid} style={{
                backgroundColor: properties.backgroundColor,
            }}>
                <div className={styles.leftSide}>
                    <LeadGenForm
                        onSubmit={async (data, done) => {
                            try {
                                let lead = {
                                    fields: data,
                                    isComplete: true
                                }
                                await updateLead(dispatch, state.code, lead)
                                window.scrollTo(0, 0)
                            } catch (err) {
                                alert('Oops something went wrong')
                                console.error(err)
                            } finally {
                                done()
                            }
                        }}
                        initialValues={initialValues}
                    />
                </div>

                <div className={styles.rightSide}>
                    <SideBar />
                </div>
            </div>

            <Footer />
        </>
    )
}
