import React from 'react'
import { Store } from 'Store'
import { TextBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import withTheme from 'hoc/withTheme'

import Testimonials from 'components/Testimonials'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'
import propertiesSchema from './properties.schema.json'

const DEFAULT_MESSAGE = `
    <h4>{firstName}, your donation helps to make a difference in our students lives.</h4>
`

function SideBar (props) {
    const { ref, properties } = useBlock('sidebar', propertiesSchema)
    const { state } = React.useContext(Store)

    return (
        <div
            ref={ref}
            style={{
                '--background-color': properties.backgroundColor
            }}
            className={styles.sidebar}
        >
            <div className={styles.message}>
                <TextBlock
                    style={{
                        '--heading-color': properties.headingColor,
                        '--color': properties.color,
                    }}
                    blockId="sidebar.message"
                    values={{ ...state.lead.fields }}
                    valuesSchema={state.campaign.mergeTags}
                    locale={state.locale}
                    defaultContent={DEFAULT_MESSAGE}
                    onAnchorClick={createClickActivity}
                />
            </div>

            {properties.testimonials && (
                <div className={styles.testimonials}>
                    <Testimonials/>
                </div>
            )}
        </div>
    )
}

export default withTheme(supportedThemes)(SideBar)
