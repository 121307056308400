// @flow
import queryString from 'query-string'
import merge from 'deepmerge'
import URL from 'url-parse'

import { Client, FetchClient, CookieFetchClient, Lead, MockClient } from '@shift-marketing/shift-one-api-client'

import mockDataOverrides from 'mock-client-overrides.js'

const query = queryString.parse(window.location.search)

function makeClient (baseUri: ?string): Client {
    if (typeof query['demo'] !== 'undefined') {
        let options = mockDataOverrides

        if (query['demo']) {
            try {
                const demo = JSON.parse(query['demo'])
                options = merge(options, demo)
            } catch (error) {
                throw new Error('Unable to parse JSON')
            }
        }

        if (typeof window['__CAMPAIGN__'] !== 'undefined') {
            return new CookieFetchClient(baseUri)
        }

        return new MockClient('higher-education', options)
    }

    return new FetchClient(baseUri)
}

let baseUrl = process.env.REACT_APP_SHIFT_API_BASE_URL


if ((new URLSearchParams(window.location.search)).has('sandbox')) {
    baseUrl = baseUrl.replace(/app\./, 'sandbox.')
}

const APIClient = makeClient(baseUrl)

let entrypoint = window.sessionStorage.getItem('entrypoint')
if (!entrypoint || (new URL(entrypoint)).pathname.split('/')[1] !== window.location.pathname.split('/')[1]) {
    entrypoint = query._impersonate
        ? `${window.location.protocol}//${query._impersonate}`
        : window.location.href

    window.sessionStorage.setItem('entrypoint', entrypoint)
}

const campaignUrl = new URL(entrypoint)

// deprecated
export const fetchCampaign = (dispatch: any => void) => {
    (async function () {
        try {
            const campaign = await APIClient.getCampaign(campaignUrl)

            campaign.expiryDate = new Date(campaign.clientExpiryDate)

            dispatch({
                type: 'SET_CAMPAIGN',
                payload: { campaign, campaignUrl }
            })

            dispatch({
                type: 'SET_LOCALE',
                payload: campaign.locales.default
            })
        } catch (error) {
            if (error.name === 'CampaignNotFoundError') {
                dispatch({ type: 'CAMPAIGN_NOT_FOUND' })
            }
        }
    })()
}

export const fetchCampaignAndLead = (dispatch: any => void) => {
    (async function () {
        try {
            const campaign = await APIClient.getCampaign(campaignUrl)

            campaign.expiryDate = new Date(campaign.clientExpiryDate)

            dispatch({
                type: 'SET_CAMPAIGN',
                payload: { campaign, campaignUrl }
            })

            const theme = await import(`themes/${campaign.style}`)

            dispatch({
                type: 'SET_DEFAULT_THEME',
                payload: theme.default,
            })

            dispatch({
                type: 'SET_LOCALE',
                payload: campaign.locales.default
            })

            const lead = await APIClient.getLead(campaignUrl)

            dispatch({
                type: 'SET_LEAD',
                payload: lead
            })
        } catch (error) {
            console.error(error)
            alert('Something went wrong.')
        }
    })()
}

export const fetchLead = (dispatch: any => void) => {
    (async function () {
        const lead = await APIClient.getLead(campaignUrl)

        dispatch({
            type: 'SET_LEAD',
            payload: lead
        })
    })()
}

export async function updateLead (dispatch: any => void, code: ?string, lead: Lead) {
    const updatedLead = await APIClient.updateLead(campaignUrl, code, lead)

    dispatch({
        type: 'SET_LEAD',
        payload: updatedLead
    })

    if (lead.isComplete) {
        dispatch({ type: 'MARK_AUTORESPONDER_AS_PENDING' })
    }
}

export async function patchLead (dispatch: any => void, code: ?string, fields: any, isComplete: boolean) {
    const lead = await APIClient.patchLead(campaignUrl, code, fields, isComplete)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })

    if (isComplete) {
        dispatch({ type: 'MARK_AUTORESPONDER_AS_PENDING' })
    }
}

export function deleteLead (dispatch: any => void) {
    dispatch({
        type: 'SET_LEAD',
        payload: null
    })
}

export async function sendAutoresponder (dispatch: any => void, voucherName: string, voucher: File) {
    dispatch({
        type: 'MARK_AUTORESPONDER_AS_SENT',
        payload: null
    })

    APIClient.sendAutoresponder(campaignUrl, { [voucherName]: voucher })
}

export async function createClickActivity(clickedUrl: string, label: ?string) {
    try {
        await APIClient.createClickActivity(campaignUrl, new URL(clickedUrl), label)
    } catch (error) {
        console.error(error)
        alert('Something went wrong.')
        throw error
    }
}
