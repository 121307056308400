// @flow

import * as React from 'react'

import { ImageBlock, TextBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import HeaderImage from 'components/HeaderImage'
import TopBar from 'components/TopBar'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

Header.defaultProps = {
    date: new Date()
}

const DEFAULT_HEADER_MESSAGE = `
    <h2>{firstName}, thank you for being a part of their story.</h2>
    <p>Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive. Thank you so very much for your generosity.</p>  
`

export default function Header () {
    const { state } = React.useContext(Store)
    const { ref, properties } = useBlock('landing.header', propertiesSchema)

    return (
        <div
            ref={ref}
            className={styles.container}
            style={{ backgroundColor: properties.backgroundColor }}
        >
            <TopBar/>

            <div className={styles.containerGrid}>
                <div className={styles.leftSide}>
                    <div className={styles.imgContainer}>
                        <ImageBlock
                            blockId="header.logo"
                            defaultImage="$.logo.regular"
                            resize='both'
                            maxWidth='50%'
                            title='Header logo'
                        />
                    </div>

                    <TextBlock
                        className={styles.headerMessage}
                        style={{
                            '--heading-color': properties.headingColor,
                            '--color': properties.bodyColor
                        }}
                        blockId="masthead.message"
                        values={{ ...state.lead.fields }}
                        valuesSchema={state.campaign.mergeTags}
                        locale={state.locale}
                        defaultContent={DEFAULT_HEADER_MESSAGE}
                        onAnchorClick={createClickActivity}
                    />
                </div>

                <div className={styles.rightSide}>
                    <HeaderImage/>
                </div>
            </div>
        </div>
    )
}
